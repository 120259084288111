<template>
	<div class="desktop-nav">
		<div class="left">
			<img
				src="../assets/img/logo-b.webp"
				alt="kongu-logo"
				v-on:click="openTwitter"
			/>
		</div>
		<div class="mid">
			<li>
				<router-link to="/" v-on:click="closeNav">Home</router-link>
			</li>
			<li>
				<router-link to="/about" v-on:click="closeNav"
					>About</router-link
				>
			</li>
			<li>
				<router-link to="/vision" v-on:click="closeNav"
					>Vision</router-link
				>
			</li>
			<li>
				<router-link to="/gallery" v-on:click="closeNav"
					>Gallery</router-link
				>
			</li>
			<li>
				<router-link to="/treehouse" v-on:click="closeNav"
					>Treehouse</router-link
				>
			</li>
			<li>
				<router-link to="/staking" v-on:click="closeNav"
					>Staking</router-link
				>
			</li>
			<li>
				<router-link to="/arcade" v-on:click="closeNav"
					>Arcade</router-link
				>
			</li>
			<li @click="goToDiscord()">
				<router-link to="" v-on:click="closeNav">Dashboard</router-link>
			</li>
		</div>
		<div class="right">
			<router-link to="/bananu">
				<img src="../assets/img/banan.png" alt="bananu-logo"
			/></router-link>
		</div>
	</div>

	<div class="mobile-nav-icons">
		<img
			class="burger-icon"
			src="../assets/img/burger-icon.png"
			alt="burger-icon"
			v-if="!navOpen"
			v-on:click="toggleNav"
		/>
		<img
			class="close-icon"
			src="../assets/img/close-icon.png"
			alt="close-icon"
			v-if="navOpen"
			v-on:click="closeNav"
		/>
	</div>
	<div class="mobile-nav" v-if="navOpen">
		<ul>
			<li class="li">
				<router-link to="/" v-on:click="closeNav">Home</router-link>
			</li>
			<li class="li">
				<router-link to="/about" v-on:click="closeNav"
					>About</router-link
				>
			</li>
			<li class="li">
				<router-link to="/vision" v-on:click="closeNav"
					>Vision</router-link
				>
			</li>
			<li class="li">
				<router-link to="/gallery" v-on:click="closeNav"
					>Gallery</router-link
				>
			</li>
			<li class="li">
				<router-link to="/treehouse" v-on:click="closeNav"
					>Treehouse</router-link
				>
			</li>
			<li class="li" @click="goToDiscord()">
				<router-link to="" v-on:click="closeNav">Dashboard</router-link>
			</li>
			<li class="li">
				<router-link to="/staking" v-on:click="closeNav"
					>Staking</router-link
				>
			</li>
			<li class="li">
				<router-link to="/arcade" v-on:click="closeNav"
					>Arcade</router-link
				>
			</li>
			<li class="bananu">
				<router-link to="/bananu" v-on:click="closeNav"
					><img src="../assets/img/bananu.png" alt="bananu"
				/></router-link>
			</li>
			<li class="li" @click="openTwitter()">Twitter</li>
			<li class="li" @click="openEther()">ethermail</li>
			<li class="li" @click="openBlur()">Blur</li>
		</ul>
	</div>
</template>

<script>
export default {
	data() {
		return {
			navOpen: false,
		};
	},
	methods: {
		goToDiscord() {
			window.open(
				"https://discord.com/api/oauth2/authorize?client_id=1026189078498128027&redirect_uri=https%3A%2F%2Fkongu.io%2Fdashboard&response_type=token&scope=identify",
				"_self"
			);
		},
		closeNav() {
			this.navOpen = false;
		},
		openTwitter() {
			window.open("https://twitter.com/KonguNFT", "_blank");
		},
		openOpensea() {
			window.open("https://opensea.io/collection/kongu", "_blank");
		},
		openEther() {
			window.open("https://ethermail.io/community/kongu", "_blank");
		},
		openBlur() {
			window.open("https://blur.io/collection/kongu", "_blank");
		},
		toggleNav() {
			this.navOpen = !this.navOpen;
		},
	},
};
</script>

<style scoped>
@font-face {
	font-family: "Futura XBlk BT", sans-serif;
	src: url("../assets/fonts/futurTitle.ttf");
}
@font-face {
	font-family: "Raleway", sans-serif;
	src: url("../assets/fonts/Raleway-Bold.ttf");
}
@font-face {
	font-family: "Futura Hv BT", sans-serif;
	src: url("../assets/fonts/futurHeavy.ttf");
}

* {
	margin: 0;
	padding: 0;
}
/* ------------------------------------ DESKTOP NAV ------------------------------------ */
.desktop-nav {
	position: absolute;
	top: 0%;
	z-index: 100;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.left {
	width: 10%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0 0 0 1vw;
}
.left img {
	width: 80px;
	transition: 1s ease;
}
.left img:hover {
	transform: rotate(360deg);
}
.mid {
	width: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.desktop-nav li {
	margin: 0 15px;
	padding: 0;
	background: hsla(0, 0%, 82.7%, 0.4);
	border-radius: 10px;
	width: 135px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	text-transform: uppercase;
	transition: 0.3s ease;
	cursor: pointer;
	font-size: 1rem;
	font-family: "Futura Hv BT";
}
.desktop-nav li a:hover {
	background: #e16428;
	color: #ece7e2;
}
.desktop-nav li a {
	display: block;
	border-radius: 10px;
	width: 135px;
	height: 35px;
	transition: 0.3s ease;
}
.right {
	width: 10%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0 1vw 0 0;
}
.right img {
	width: 115px;
	transition: 0.25s ease;
}
.right img:hover {
	transform: scale(1.05);
}
/* ------------------------------------ MOBILE NAV ------------------------------------ */
.mobile-nav-icons {
	display: none;
	position: fixed;
	top: 1.5%;
	right: 3%;
	z-index: 10000;
	align-items: center;
	justify-content: center;
}
.mobile-nav {
	display: none;
}

.burger-icon,
.close-icon {
	width: 5.5vh;
	cursor: pointer;
}
.mobile-nav ul {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	position: fixed;
	z-index: 10;
	height: 100vh;
	width: 100%;
	background: hsla(0, 0%, 82.7%, 0.4);
	padding: 7.5vh 0 0 0;
}
.mobile-nav ul .li {
	font-family: "Futura Hv BT", sans-serif;
	width: 85%;
	font-size: 2rem;
	text-transform: uppercase;
	border-bottom: 1px solid #000;
	padding: 2.5vh 0;
}
.bananu {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 85%;
	padding: 1vh 0;
	border-bottom: 1px solid #000;
}
.bananu img {
	height: 5vh;
	margin: 0 0 0 -7vw;
}

/*-------------------- MEDIA QUERIES --------------------*/
@media screen and (max-width: 3840px) {
	.left img {
		width: 160px;
	}
	.right img {
		width: 230px;
	}
	.desktop-nav li {
		margin: 0 30px;
		border-radius: 20px;
		width: 270px;
		height: 70px;
		line-height: 70px;
		font-size: 2rem;
	}
	.desktop-nav li a {
		border-radius: 20px;
		width: 270px;
		height: 70px;
	}
}
@media screen and (max-width: 3100px) {
	.left img {
		width: 120px;
	}
	.right img {
		width: 172.5px;
	}
	.desktop-nav li {
		margin: 0 30px;
		border-radius: 15px;
		width: 202.5px;
		height: 52.5px;
		line-height: 52.5px;
		font-size: 1.5rem;
	}
	.desktop-nav li a {
		border-radius: 15px;
		width: 202.5px;
		height: 52.5px;
	}
}
@media screen and (max-width: 2125px) {
	.left img {
		width: 80px;
	}
	.right img {
		width: 115px;
	}
	.desktop-nav li {
		margin: 0 15px;
		border-radius: 10px;
		width: 135px;
		height: 35px;
		line-height: 35px;
		font-size: 1rem;
	}
	.desktop-nav li a {
		border-radius: 10px;
		width: 135px;
		height: 35px;
	}
}
@media screen and (max-width: 1600px) {
	.left img {
		width: 64px;
	}
	.right img {
		width: 92px;
	}
	.desktop-nav li {
		margin: 0 12px;
		border-radius: 8px;
		width: 108px;
		height: 28px;
		line-height: 28px;
		font-size: 0.8rem;
	}
	.desktop-nav li a {
		border-radius: 8px;
		width: 108px;
		height: 28px;
	}
}
@media screen and (max-width: 1200px) {
	.left img {
		width: 57.6px;
	}
	.right img {
		width: 82.8px;
	}
	.desktop-nav li {
		margin: 0 10.8px;
		border-radius: 7.2px;
		width: 97.2px;
		height: 25.2px;
		line-height: 25.2px;
		font-size: 0.72rem;
	}
	.desktop-nav li a {
		border-radius: 7.2px;
		width: 97.2px;
		height: 25.2px;
	}
}
@media screen and (max-width: 1000px) {
	.desktop-nav {
		display: none;
	}
	.mobile-nav-icons {
		display: flex;
	}
	.mobile-nav {
		display: block;
	}
}
@media screen and (max-width: 900px) {
	.mobile-nav ul .li {
		font-size: 1.75rem;
		padding: 2.25vh 0;
	}
}
@media screen and (max-width: 800px) {
	.mobile-nav ul .li {
		font-size: 1.6rem;
		padding: 2.25vh 0;
	}
}
@media screen and (max-width: 700px) {
	.mobile-nav ul .li {
		font-size: 1.25rem;
		padding: 2vh 0;
	}
}
@media screen and (max-width: 450px) {
	.mobile-nav ul {
		padding: 10vh 0 0 0;
	}
	.mobile-nav-icons {
		top: 1.5%;
		right: 5%;
	}
	.burger-icon,
	.close-icon {
		width: 6.5vh;
		cursor: pointer;
	}
	.bananu img {
		height: 5vh;
		margin: 0 0 0 -11vw;
	}
}
@media screen and (max-width: 380px) {
	.bananu {
		padding: 0;
	}
	.bananu img {
		height: 5vh;
		margin: 1.5vh 0 1vh -8.5vw;
	}
	.mobile-nav ul .li {
		font-size: 1.15rem;
		padding: 1.75vh 0;
	}
}
</style>