<template>
	<link
		rel="stylesheet"
		href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"
		integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w=="
		crossorigin="anonymous"
		referrerpolicy="no-referrer"
	/>
	<div class="links-container">
		<div class="link-indiv">
			<div class="link">
				<a href="https://discord.gg/kongu" target="_blank">
					<i class="fa-brands fa-discord"></i>
				</a>
				<a href="https://blur.io/collection/kongu" target="_blank">
					<img class="blur" src="../assets/img/blur.png" alt="blur" />
				</a>
				<a href="https://magiceden.io/collections/ethereum/0x5817b0987fef2307de7b786fbbb52b5a7a10ad95" target="_blank">
					<img class="blur" src="../assets/img/magicEden.svg" alt="blur" />
				</a>
				<a href="https://ethermail.io/community/kongu" target="_blank">
					<img src="../assets/img/ethermail.png" alt="Opensea" />
				</a>
				<a href="https://twitter.com/KonguNFT" target="_blank"
					><i
						class="fa-brands fa-twitter"
						v-on:click="openTwitter"
					></i
				></a>
			</div>
		</div>
	</div>
	<div class="music-container">
		<div class="mp3-container">
			<div class="center-me center-me" id="pButton" @click="togglePlay">
				<div class="equalizer" v-if="isPlaying"></div>
				<div class="equalizerr" v-else="isPlaying"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isPlaying: false,
			audio: new Audio(require("../assets/mp3/song.mp3")),
		};
	},
	methods: {
		togglePlay() {
			this.isPlaying ? this.audio.pause() : this.audio.play();
			this.isPlaying = !this.isPlaying;
		},
		playMusic() {
			this.isPlaying = true;
		},
	},
};
</script>

<style scoped>
/*-------------------- LINKS --------------------*/

.links-container {
	position: fixed;
	z-index: 15;
	bottom: 15px;
	left: 25px;
}
.music-container {
	position: fixed;
	z-index: 15;
	bottom: 15px;
	right: 25px;
}
.link {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.link a {
	margin-bottom: 10px;
	border: 2px solid #000;
	border-radius: 50px;
	height: 40px;
	width: 40px;
	font-size: 1.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.5s ease-in-out;
	color: #000;
	backdrop-filter: blur(5px);
}
.link a:hover {
	transform: scale(1.2);
}
.link img {
	width: 25px;
	padding-bottom: 3px;
}
.blur {
	margin: 3.5px 0 0 0;
}

/*-------------------- MUSIC --------------------*/
.mp3-container {
	position: relative;
	z-index: 5;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
}
.equalizerr {
	position: absolute;
	z-index: 5;
	bottom: 13.25px;
	left: 11.25px;
	display: block;
	width: 5px;
	background-color: black;
	height: 6px;
	text-align: center;
}

.equalizerr::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 6.15px;
	height: 15px;
	width: 5px;
	background-color: black;
}

.equalizerr::before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 6.15px;
	height: 12px;
	width: 5px;
	background-color: black;
}

.equalizerr::after {
	left: 11.5px;
}
.equalizer {
	position: absolute;
	z-index: 5;
	bottom: 13.25px;
	left: 11.25px;
	display: block;
	width: 5px;
	background-color: black;
	height: 2.5px;
}
.center-me {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid black;
	border-radius: 50%;
}
.equalizer {
	text-align: center;
}
.equalizer,
.equalizer::before,
.equalizer::after {
	animation: equalize 1.25s steps(25, end) 0s infinite;
}

.equalizer::before,
.equalizer::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 6.15px;
	height: 25px;
	width: 5px;
	background-color: black;
}

.equalizer::before {
	animation-name: equalize2;
}

.equalizer::after {
	left: 11.5px;
	animation-name: equalize3;
}

@keyframes equalize {
	0% {
		height: 5px;
	}
	4% {
		height: 2.5px;
	}
	8% {
		height: 5px;
	}
	12% {
		height: 8.75px;
	}
	16% {
		height: 12.5px;
	}
	20% {
		height: 16.25px;
	}
	24% {
		height: 15px;
	}
	28% {
		height: 13.75px;
	}
	32% {
		height: 13.75px;
	}
	36% {
		height: 10px;
	}
	40% {
		height: 12.5px;
	}
	44% {
		height: 12.5px;
	}
	48% {
		height: 13.75px;
	}
	52% {
		height: 15px;
	}
	56% {
		height: 16.25px;
	}
	60% {
		height: 15px;
	}
	64% {
		height: 15px;
	}
	68% {
		height: 13.75px;
	}
	72% {
		height: 12.5px;
	}
	76% {
		height: 13.75px;
	}
	80% {
		height: 15px;
	}
	84% {
		height: 15px;
	}
	88% {
		height: 16.25px;
	}
	92% {
		height: 12.5px;
	}
	96% {
		height: 8.75px;
	}
	100% {
		height: 5px;
	}
}
@keyframes equalize2 {
	0% {
		height: 15px;
	}
	4% {
		height: 16.25px;
	}
	8% {
		height: 15px;
	}
	12% {
		height: 15px;
	}
	16% {
		height: 13.75px;
	}
	20% {
		height: 13.75px;
	}
	24% {
		height: 13.75px;
	}
	28% {
		height: 15px;
	}
	32% {
		height: 15px;
	}
	36% {
		height: 16.25px;
	}
	40% {
		height: 16.25px;
	}
	44% {
		height: 16.25px;
	}
	48% {
		height: 13.75px;
	}
	52% {
		height: 10px;
	}
	56% {
		height: 7.5px;
	}
	60% {
		height: 10px;
	}
	64% {
		height: 12.5px;
	}
	68% {
		height: 13.75px;
	}
	72% {
		height: 16.25px;
	}
	76% {
		height: 15px;
	}
	80% {
		height: 15px;
	}
	84% {
		height: 13.75px;
	}
	88% {
		height: 13.75px;
	}
	92% {
		height: 11.25px;
	}
	96% {
		height: 13.75px;
	}
	100% {
		height: 15px;
	}
}
@keyframes equalize3 {
	0% {
		height: 11.25px;
	}
	4% {
		height: 8.75px;
	}
	8% {
		height: 11.25px;
	}
	12% {
		height: 13.75px;
	}
	16% {
		height: 16.25px;
	}
	20% {
		height: 18.75px;
	}
	24% {
		height: 17.5px;
	}
	28% {
		height: 13.75px;
	}
	32% {
		height: 11.25px;
	}
	36% {
		height: 10px;
	}
	40% {
		height: 8.75px;
	}
	44% {
		height: 6.25px;
	}
	48% {
		height: 10px;
	}
	52% {
		height: 12.5px;
	}
	56% {
		height: 13.75px;
	}
	60% {
		height: 16.25px;
	}
	64% {
		height: 15px;
	}
	68% {
		height: 13.75px;
	}
	72% {
		height: 13.75px;
	}
	76% {
		height: 12.5px;
	}
	80% {
		height: 15px;
	}
	84% {
		height: 16.25px;
	}
	88% {
		height: 17.5px;
	}
	92% {
		height: 15px;
	}
	96% {
		height: 12.5px;
	}
	100% {
		height: 11.25px;
	}
}
.center-me {
	position: relative;
	top: -10%;
	left: 0%;
	width: 40px;
	height: 40px;
	transition: 0.5s ease-in-out;
}
.center-me:hover {
	transform: scale(1.4);
}
@media screen and (max-width: 3840px) {
	.links-container {
		transform: scale(2);
		bottom: 6.5%;
		left: 2%;
	}
	.music-container {
		transform: scale(2);
		bottom: 2.75%;
		right: 2%;
	}
}
@media screen and (max-width: 2560px) {
	.links-container {
		transform: scale(1.5);
		bottom: 5%;
		left: 2%;
	}
	.music-container {
		transform: scale(1.5);
		bottom: 2.5%;
		right: 2%;
	}
}
@media screen and (max-width: 2100px) {
	.links-container {
		transform: scale(1);
		bottom: 2%;
		left: 1%;
	}
	.music-container {
		transform: scale(1);
		bottom: 2%;
		right: 1%;
	}
}
@media screen and (max-width: 1705px) {
	.links-container {
		transform: scale(0.8);
		bottom: -1%;
		left: 1%;
	}
	.music-container {
		transform: scale(0.8);
		bottom: 0.9%;
		right: 1%;
	}
}
@media screen and (max-width: 1300px) {
	.links-container {
		transform: scale(0.6);
		bottom: -5.5%;
		left: 0.75%;
	}
	.music-container {
		transform: scale(0.65);
		bottom: 0.9%;
		right: 1%;
	}
}
@media screen and (max-width: 920px) {
	.links-container {
		display: none;
	}
	.music-container {
		display: none;
	}
}
@media screen and (max-width: 630px) {
	.link {
		display: none;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.music-container {
		display: none;
	}
}
</style>
