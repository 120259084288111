<template>
	<body>
		<header v-motion-fade>
			<video
				src="../assets/videos/Home.mp4"
				autoplay
				playsinline
				muted
				loop
				id="content"
			></video>
		</header>
	</body>
</template>

<script>
// @ is an alias to /src
</script>

<style scoped>
/*-------------------- VIDEO --------------------*/
* {
	margin: 0;
	padding: 0;
	background: #ece7e2;
}
header {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
	z-index: 0;
	background: #ece7e2;
	overflow: hidden;
	text-align: center;
	transition: 0.75s ease;
}
video {
	clip-path: inset(1px 1px);
}
#content {
	width: 90%;
}

@media screen and (max-width: 1750px) {
	#content {
		width: 85%;
	}
}
@media screen and (max-width: 1600px) {
	#content {
		height: 90%;
	}
}
</style>
