<template>
	<div class="preloader">
		<img src="../assets/img/logo-site.gif" alt="logo-site" />
		<div class="loader"></div>
	</div>
</template>

<script>
export default {
	mounted() {
		const preloader = document.querySelector(".preloader");
		if (preloader) {
			setTimeout(() => {
				const fadeEffect = setInterval(() => {
					if (!preloader.style.opacity) {
						preloader.style.opacity = 1;
					}
					if (preloader.style.opacity > 0) {
						preloader.style.opacity -= 0.1;
					} else {
						preloader.style.display = "none";
						clearInterval(fadeEffect);
					}
				}, 0.7);
			}, 3400);
		}
	},
};
</script>

<style scoped>
/* -------------------- PRELOADER -------------------- */

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: #ece7e2;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;
}
.preloader img {
	width: 250px;
	padding-bottom: 25px;
	clip-path: inset(1px 1px);
	-webkit-clip-path: inset(1px 1px);
	outline: none;
}
.loader {
	display: block;
	width: 350px;
	height: 20px;
	border-radius: 50px;

	top: 45%;
	top: -webkit-calc(50% - 10px);
	top: calc(50% - 10px);
	left: 25%;
	left: -webkit-calc(50% - 75px);
	left: calc(50% - 75px);

	background-color: rgba(255, 255, 255, 0.2);
}
.loader:before {
	content: "";
	display: block;
	background: rgb(16, 12, 12);
	top: 0px;
	left: 0px;
	height: 20px;
	width: 0px;
	border-radius: 50px;
	z-index: 0;
	opacity: 1;

	-webkit-transform-origin: 100% 0%;
	transform-origin: 100% 0%;
	-webkit-animation: loader 3.8s ease-in-out infinite;
	animation: loader 3.8s ease-in-out infinite;
}
@-webkit-keyframes loader {
	0% {
		width: 0px;
	}
	70% {
		width: 100%;
		opacity: 1;
	}
	90% {
		opacity: 1;
		width: 100%;
	}
	100% {
		opacity: 1;
		width: 0px;
	}
}

@keyframes loader {
	0% {
		width: 0px;
	}
	70% {
		width: 100%;
		opacity: 1;
	}
	90% {
		opacity: 1;
		width: 100%;
	}
	100% {
		opacity: 1;
		width: 0px;
	}
}
@keyframes rotate {
	0% {
		rotate: 0deg;
	}
	100% {
		rotate: 360deg;
	}
}
@media (max-width: 3840px) {
	.preloader img {
		width: 500px;
		padding-bottom: 25px;
	}
	.loader {
		display: block;
		width: 600px;
		height: 20px;
		border-radius: 50px;
	}
}
@media (max-width: 2560px) {
	.preloader img {
		width: 400px;
		padding-bottom: 25px;
	}
	.loader {
		display: block;
		width: 500px;
		height: 20px;
		border-radius: 50px;
	}
}
@media (max-width: 1920px) {
	.preloader img {
		width: 250px;
		padding-bottom: 25px;
		clip-path: inset(1px 1px);
		-webkit-clip-path: inset(1px 1px);
		outline: none;
	}
	.loader {
		display: block;
		width: 350px;
		height: 20px;
		border-radius: 50px;

		top: 45%;
		top: -webkit-calc(50% - 10px);
		top: calc(50% - 10px);
		left: 25%;
		left: -webkit-calc(50% - 75px);
		left: calc(50% - 75px);

		background-color: rgba(255, 255, 255, 0.2);
	}
}

@media (max-width: 420px) {
	.preloader img {
		width: 200px;
		padding-bottom: 25px;
	}
	.loader {
		display: block;
		width: 250px;
		height: 20px;
		border-radius: 50px;
	}
}
</style>
