<template>
	<div id="app">
		<navbar />
		<preloader />
		<links />
		<router-view />
	</div>
</template>

<script>
import links from "./components/links.vue";
import navbar from "./components/navbar.vue";
import preloader from "./components/preloader.vue";

export default {
	name: "App",
	components: {
		preloader,
		links,
		navbar,
	},
};
</script>

<style>
#app {
	margin: 0;
	padding: 0;
	height: -webkit-fill-available;
	/* background: #ece7e2; */
}
@font-face {
	font-family: "Futura XBlk BT";
	src: url("./assets/fonts/futurTitle.ttf");
}
@font-face {
	font-family: "Raleway", sans-serif;
	src: url("./assets/fonts/Raleway-Bold.ttf");
}
@font-face {
	font-family: "Futura Hv BT";
	src: url("./assets/fonts/futurHeavy.ttf");
}

* {
	margin: 0;
	padding: 0;
	color: #000;
	list-style-type: none;
	text-decoration: none;
	/* cursor: url(./assets/img/color-sunlight-cursor.png), default; */
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
</style>
