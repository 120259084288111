import { createRouter, createWebHistory } from "vue-router";
// import EnterView from "../views/EnterView.vue";
import HomeView from "../views/HomeView.vue";

const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView,
	},
	{
		path: "/about",
		name: "about",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
	},
	{
		path: "/vision",
		name: "vision",
		component: () =>
			import(/* webpackChunkName: "vision" */ "../views/VisionView.vue"),
	},
	{
		path: "/gallery",
		name: "gallery",
		component: () =>
			import(
				/* webpackChunkName: "gallery" */ "../views/GalleryView.vue"
			),
	},
	{
		path: "/dashboard",
		name: "dashboard",
		component: () =>
			import(
				/* webpackChunkName: "dashboard" */ "../views/DashboardView.vue"
			),
	},
	{
		path: "/account/:wallet",
		name: "account",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/AccountView.vue"),
	},
	{
		path: "/treehouse",
		name: "treehouse",
		component: () =>
			import(
				/* webpackChunkName: "verify" */ "../views/TreehouseView.vue"
			),
	},
	{
		path: "/bananu",
		name: "bananu",
		component: () =>
			import(/* webpackChunkName: "bananu" */ "../views/BananuView.vue"),
	},
	// {
	// 	path: "/mint",
	// 	name: "mint",
	// 	component: () =>
	// 		import(/* webpackChunkName: "bananu" */ "../views/MintView.vue"),
	// },
	{
		path: "/innerhouse",
		name: "inner-house",
		component: () =>
			import(
				/* webpackChunkName: "innerhouse" */ "../views/InnerHouseView.vue"
			),
	},
	{
		path: "/staking",
		name: "staking",
		component: () =>
			import(
				/* webpackChunkName: "staking" */ "../views/StakingView.vue"
			),
	},
	{
		path: "/arcade",
		name: "arcade",
		component: () =>
			import(
				/* webpackChunkName: "marketplace" */ "../views/MarketplaceView.vue"
			),
	},
	{
		path: "/transferKoingu",
		name: "transferKoingu",
		component: () =>
			import(
				/* webpackChunkName: "marketplace" */ "../views/TransferView.vue"
			),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
