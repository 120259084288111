import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { MotionPlugin } from "@vueuse/motion";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import VTooltip from "v-tooltip";

createApp(App)
	.use(store)
	.use(ElementPlus)
	.use(router)
	.use(MotionPlugin)
	.use(VTooltip)
	.mount("#app");
